html,
body {
	height: 100%;
	padding: 0;
	margin: 0;
}

html {
	overflow-x: hidden;
}

body {
	background-color: $body-bg-color;
	color: $body-font-color;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-family: $font-family-base;
	font-weight: $body-font-weight;
}


/* Font Size on Mobile devices */
@media (max-width: 479px) {
	body {
		font-size: $font-size-mobile;
		line-height: $line-height-mobile;
	}
}

.site-wrapper {
	backface-visibility: hidden;
	transition: all 0.4s ease-in-out;
}
	.site-wrapper--has-overlay {
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		height: 100vh;
	}

.site-overlay {
	left: 0;
	top: 0;
	z-index: 102;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: transparent;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.4s linear;

	.site-wrapper--has-overlay & {

		@media (max-width: 991px) {
			visibility: visible;
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	.site-wrapper--has-overlay-pushy & {

		@media (min-width: 992px) {
			visibility: visible;
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.8);
		}
	}
}
