/* Widget: Soccer Lineup */
.widget-lineup {

  .widget__content {
    text-align: center;
  }
}
  .soccer-lineup {
    background: url("../images/soccer/lineup-bg.png") 0 0 no-repeat;
    background-size: cover;
    max-width: 100%;
  }
