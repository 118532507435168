/* Main News Banner
/* ------------------------- */
.main-news-banner {
	background-color: #27313b;
	overflow: hidden;
	position: relative;
	border-radius: 4px;
	margin-bottom: 15px;

	.posts__title {

		> a {
			color: #fff;
		}
	}
		.main-news-banner__highlight {

			@if $template == 'soccer' {
				color: $color-4-darken;
			} @else {
				color: $color-primary;
			}
		}
}
	.main-news-banner__inner {
		padding: 20px;
		position: relative;
		z-index: 2;

		@media (min-width: 992px) {
			padding: 35px 30px 35px 30px;
		}

		@media (min-width: 1199px) {
			padding: 55px 40px 55px 55px;
		}
	}


/* With Image at the Left */
.main-news-banner--img-left {

	&::before {
		content:"";
		display: block;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-image: radial-gradient(at 0% 0%, rgba(255, 255, 255, 0.8), transparent);
		background-repeat: no-repeat;
		background-position: 0 0;
		mix-blend-mode: overlay;

		@media (min-width: 992px) {
			background-image: radial-gradient(at 180px 0%, rgba(255, 255, 255, 0.8), transparent);
		}
	}

	.main-news-banner__img {
		float: left;
		max-width: 50%;
		position: relative;
		z-index: 2;

		&::before {
			content:"";
			display: block;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 23%;
			background-color: $color-primary;
			width: 50%;
		}

		img {
			position: relative;
			z-index: 2;
		}
	}

	.posts__excerpt {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	.posts__date {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	.posts__more {

		@media (max-width: 479px) {
			padding-top: 10px;
		}

		.btn {

			@media (max-width: 479px) {
				padding-left: 20px;
				padding-right: 15px;
				padding-top: 6px;
				padding-bottom: 6px;
				font-size: 10px;
				border-width: 1px;
			}
		}
	}
}


/* With Background */
.main-news-banner--bg {
	background-image: url("../images/samples/main-news-banner__bg.jpg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	&::before {
		content:"";
		display: block;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-image: linear-gradient(to left, rgba(0,0,0,.8), transparent);

		@media (min-width: 992px) {
			background-image: linear-gradient(to left, rgba(0,0,0,.8), transparent)
		}
	}

	.main-news-banner__inner {
		padding: 30px;

		@media (min-width: 992px) {
			padding: 30px 30px 30px 48%;
		}

		@media (min-width: 1199px) {
			padding: 41px 40px 41px 48%;
		}
	}

	.posts__date {
		color: rgba(255,255,255,.8);
	}
}


@if $template == 'soccer' {

	/* with Soccer Ball */
	.main-news-banner--soccer-ball {
		background-image: url("../images/soccer/samples/banner-soccer-ball.png");
		background-repeat: no-repeat;
		background-position: 100% 100%;

		@media (max-width: 1199px) {
			background-size: 40% auto;
		}


		.main-news-banner__inner {
			padding-right: 20%;

			@media (min-width: 992px) {
				padding-right: 30%;
			}

			@media (min-width: 1200px) {
				padding-right: 30%;
			}
		}

		.posts__excerpt {
			padding-right: 20px;

			@media (min-width: 992px) {
				padding-right: 40px;
			}

			@media (min-width: 1200px) {
				padding-right: 60px;
			}
		}
	}
}


@if $template == 'football' {

	.main-news-banner {
		background-color: $card-bg;
		border: 1px solid $card-border-color;

		/* With Background */
		&.main-news-banner--bg {
			background-image: url("../images/football/banner-helmet.png");
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: auto;

			@media (max-width: 767px) {
				background-size: 45% auto;
			}

			&::before {
				display: none;
			}

			.main-news-banner__inner {
				padding: 30px 30px 30px 50%;

				@media (min-width: 992px) {
					padding: 40px 40px 40px 50%;
				}

				@media (min-width: 1199px) {
					padding: 56px 45px 100px 50%;
				}
			}

			.posts__date {
				color: rgba(255,255,255,.8);

				@media (max-width: 479px) {
					display: none;
				}
			}

			.posts__title {
				font-size: 34px;
				line-height: 1em;
				margin-bottom: .3em;

				@media (max-width: 479px) {
					font-size: 16px;
				}
			}

			.posts__excerpt {

				@media (max-width: 479px) {
					display: none;
				}

				@media (min-width: 1199px) {
					margin-top: 21px;
				}
			}
		}
	}
}


@if $template == 'esports' {

	.main-news-banner--bg {
		background-image: url("../images/esports/samples/banner-bg.jpg");
		background-position: 0 0;

		&::before {
			display: none;
		}

		.main-news-banner__img {

			&::before {
				display: none;
			}
		}

		.posts__title {
			margin-bottom: .5em;

			@media (min-width: 1200px) {
				font-size: 32px;
				line-height: 1em;
			}
		}

		.posts__excerpt {

			@media (min-width: 480px) {
				display: block;
			}

			@media (min-width: 480px) and (max-width: 768px) {
				font-size: 11px;
				line-height: 1.5em;
			}
		}

		.btn-icon-right {

			.fa {

				@media (max-width: 479px) {
					display: none;
				}
			}
		}


		// .main-news-banner__inner {
		// 	padding-right: 20%;

		// 	@media (min-width: 992px) {
		// 		padding-right: 30%;
		// 	}

		// 	@media (min-width: 1200px) {
		// 		padding-right: 30%;
		// 	}
		// }

		// .posts__excerpt {
		// 	padding-right: 20px;

		// 	@media (min-width: 992px) {
		// 		padding-right: 40px;
		// 	}

		// 	@media (min-width: 1200px) {
		// 		padding-right: 60px;
		// 	}
		// }
	}
}
